import { format } from 'date-fns'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { colorFromUuid } from 'uuid-color'

import { columns, columnKeys } from '../../../components/Table/BargesTable'
import { ELLIPSIS } from '../../../constants/constants'
import { BargeInPool } from '../../../generated/graphql'
import { browserInfo } from '../../../lib/browser-info'
import { toString } from '../../../utils/date'

import type { NominationData, NominationVersionData } from '../../../models/models'
import type { UserDetails } from '../../Account/AuthorizationContext'

export const collectVersionedFeedbackData = (
  userInfo: UserDetails,
  nomination: NominationData,
  versions: NominationVersionData[],
  bargePool: BargeInPool[],
  exportTime: Date,
  BargeGroupIds: string[]
) => {
  const workbook = new ExcelJS.Workbook()
  const feedbackWorksheet = workbook.addWorksheet('Feedback')
  const bargePoolWorksheet = workbook.addWorksheet('Barge Pool')
  const { email, cognitoUsername } = userInfo
  const info = browserInfo()
  const recordTimeString = toString(new Date(exportTime))

  const feedbackSheetData = [
    ['User Info'],
    ['Email', email],
    ['Username', cognitoUsername],
    [],
    ['Browser Info'],
    ['Browser', info.browser],
    ['Browser Version', info.browserVersion],
    ['OS', info.os],
    ['Device', info.device],
    [],
    ['Nomination Info'],
    ['Nomination ID', nomination.id],
    ['TBO Link Status', nomination.tboLinkStatus],
    ['TBO Number', nomination.tboNumber ?? ELLIPSIS],
    [],
    ['Pool Export Time', recordTimeString],
    ['Nomination Version', 'Creation Time', 'Pool Validity'],
    ...versions.map(version => [version.id, toString(version.recordTime), isPoolValid(version.recordTime, exportTime)]),
  ]

  feedbackSheetData.forEach(row => feedbackWorksheet.addRow(row))

  versions.forEach(version => {
    const versionData = [
      [`Version (${version.id})`],
      ['Type', version.type],
      ['Record Time', toString(version.recordTime)],
      ['Tow Boat', version.tow.boat?.name ?? ELLIPSIS],
      ['Total Barges', version.tow.barges.length],
      ['Turnboat', version.tow.hasTurnboat ? 'Yes' : 'No'],
      [],
      ['Barges'],
      ['ID', 'Name', 'Load Status'],
      ...version.tow.barges.map(barge => [barge.id, barge.name ?? ELLIPSIS, barge.loadStatus]),
      [],
      ['TBN Barges'],
      ['Pickup Facility', 'Dropoff Facility', 'Expected Load Status'],
      ...(version.tow.tbnBarges || []).map(tbn => [
        tbn.pickupFacility.name,
        tbn.dropOffFacility.name,
        tbn.expectedLoadStatus,
      ]),
    ]

    const creationDate = toString(version.recordTime || new Date())
      .replace(/[:\\/?*[\]]/g, '-')
      .trim()

    const versionSheet = workbook.addWorksheet(`Version ${creationDate}`)
    versionData.forEach(row => versionSheet.addRow(row))
  })

  const bargePoolSheetData = [
    [...columnKeys.map(key => columns[key].label), 'Group ID'],
    ...bargePool.map(barge => {
      const groupId = barge.group?.uuid ?? ELLIPSIS
      const row = [...columnKeys.map(key => columns[key].format(barge) ?? ELLIPSIS), groupId]
      return row
    }),
  ]

  bargePoolSheetData.forEach(row => {
    const groupId = row[row.length - 1]
    const rowAdded = bargePoolWorksheet.addRow(row)

    getGroupColor(rowAdded, groupId, BargeGroupIds)
  })

  const exportDate = format(new Date(), 'yyyy-MM-dd')
  const exportFileName = `feedback-${nomination.id}-${exportDate}.xlsx`

  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, exportFileName)
  })
}

function isPoolValid(creationTime: Date, recordTime: Date): boolean {
  const diffMinutes = (recordTime.getTime() - creationTime.getTime()) / (1000 * 60)
  return diffMinutes < 15
}

function getGroupColor(rowAdded: ExcelJS.Row, groupId: any, BargeGroupIds: string[]): void {
  if (!groupId || groupId === ELLIPSIS) {
    return
  }

  const color = BargeGroupIds.includes(groupId) ? colorFromUuid(groupId) : null
  const cell = rowAdded.getCell(rowAdded.cellCount)

  if (color) {
    const hexColor = color.replace('#', '')
    cell.style.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: hexColor },
    }
  }
}
