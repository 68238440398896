import { useEffect, useState } from 'react'

import * as R from 'ramda'
import { useLocation } from 'wouter'

import { ELLIPSIS } from '../../../constants/constants'
import { showRiverLocation } from '../../../Domain/River'
import { LaneId, OwnedBy, TboLinkStatus } from '../../../generated/graphql'
import useNominationsModel from '../../../models/useNominationsModel'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { toString } from '../../../utils/date'

import type { LatestNominationSummary } from '../../../models/models'

export interface NominationCard {
  id: string
  title: string
  owner: string
  towShape: string
  tboLinkStatus: TboLinkStatus
  tboNumber: string
  imported: boolean
  recordTime: string
  expired: boolean
  
  open: () => void
}

export interface NominationsViewModel {
  fetching: boolean
  nominations: NominationCard[]
  newNomination: () => void
  ownershipFilter: OwnedBy
  setOwnershipFilter: (ownership: OwnedBy) => void
  refreshNominations: () => void
}

export const OwnershipOptions = {
  [OwnedBy.Me]: 'Me',
  [OwnedBy.Tbo]: 'TBO',
  [OwnedBy.Everyone]: 'Anyone',
}

const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

function formatDate(value: Date) {
  return toString(value, DATE_FORMAT)
}

export function showTboLinkStatus(status: TboLinkStatus): string {
  return {
    [TboLinkStatus.NotEstablished]: 'Not Established',
    [TboLinkStatus.Established]: 'Established',
    [TboLinkStatus.Broken]: 'Broken',
  }[status]
}

function createNominationCard(
  nomination: LatestNominationSummary,
  navigate: () => void,
  lanes: Record<LaneId, string>
): NominationCard {
  const lane = nomination.lane ? lanes[nomination.lane] : ELLIPSIS
  const origin = nomination.origin ? showRiverLocation(nomination.origin) : ELLIPSIS
  const destination = nomination.destination ? showRiverLocation(nomination.destination) : ELLIPSIS

  const owner = nomination.owner ?? 'Imported from TBO'

  const empty = nomination.towShape?.empty ?? ELLIPSIS
  const loaded = nomination.towShape?.loaded ?? ELLIPSIS
  const strings = nomination.towShape?.strings ?? ELLIPSIS
  const towShape = nomination.towShape?.strings
    ? `${loaded} loaded, ${empty} empty, ${strings} strings`
    : `${loaded} loaded, ${empty} empty`

  const title = `${lane}: ${origin} to ${destination}`
  return {
    id: nomination.id,
    title,
    owner,
    imported: !nomination.owner,
    towShape,
    recordTime: formatDate(nomination.recordTime),
    tboLinkStatus: nomination.tboLinkStatus,
    tboNumber: nomination.tboNumber ? `${nomination.tboNumber}` : ELLIPSIS,
    expired: nomination.expired,
    open: navigate,
  }
}

const useNominationsViewModel = (): NominationsViewModel => {
  const [ownershipFilter, setOwnershipFilter] = useState<OwnedBy>(OwnedBy.Me)

  const { lanes } = useSettingsContext()

  const { fetching, nominations, refreshNominations } = useNominationsModel(ownershipFilter)

  const [refetchOnEntry, setRefetch] = useState<boolean>(false)

  useEffect(() => {
    if (!refetchOnEntry) {
      refreshNominations({ requestPolicy: 'network-only' })
      setRefetch(true)
    }
  }, [refetchOnEntry, setRefetch, refreshNominations])

  useEffect(() => {
    const ref = setInterval(() => {
      refreshNominations({ requestPolicy: 'network-only' })
    }, 2 * 60_000)

    return () => clearInterval(ref)
  }, [refreshNominations])

  const [, navigate] = useLocation()

  const nominationCards: NominationCard[] = R.pipe(
    R.sortBy((n: LatestNominationSummary) => n.recordTime),
    R.reverse,
    R.map((n: LatestNominationSummary): NominationCard => {
      const navigationHandler = () => navigate(`/nomination/${n.id}`)
      return createNominationCard(n, navigationHandler, lanes)
    })
  )(nominations)

  const newNomination = () => navigate('/nominations/new')

  return {
    fetching,
    newNomination,
    ownershipFilter,
    setOwnershipFilter,
    nominations: nominationCards,
    refreshNominations: () => refreshNominations({ requestPolicy: 'network-only' }),
  }
}

export default useNominationsViewModel
