import React from 'react'

import { Autorenew, WarningRounded } from '@mui/icons-material'
import classnames from 'classnames'

import Button from '../../../components/Button'
import { BargePoolUpdateBlock, PrimaryMenu } from '../../../components/Header/PrimaryMenu/PrimaryMenu'
import KVSelector from '../../../components/Header/Selector/KVSelector'
import { Layout } from '../../../ui/Layout/Layout'
import { LoadingSpinner } from '../../../ui/Spinner/Spinner'

import styles from './styles.module.scss'
import useNominationsViewModel, { type NominationCard, OwnershipOptions, showTboLinkStatus } from './viewModel'

const NominationTile = (nomination: NominationCard) => {
  return (
    <div
      className={classnames(styles.nomination, { 
        [styles.imported]: nomination.imported,
        [styles.expired]: nomination.expired
      })}
      onClick={() => nomination.open()}
      key={nomination.id}
    >
      <div className={styles.title}>
        <span>{nomination.title}</span>
        {nomination.expired && (
          <WarningRounded className={classnames(styles.expiredIcon, styles.expire)} />
        )}
      </div>
      <div className={styles.uuid}>{nomination.id}</div>
      <div className={styles.details}>
        <div className={styles.general}>
          <span className={styles.label}>Tow Shape:</span>
          <span className={styles.value}>{nomination.towShape}</span>
          <span className={styles.label}>Created by:</span>
          <span className={styles.value}>{nomination.owner}</span>
          <span className={styles.label}>Creation Time:</span>
          <span className={styles.value}>{nomination.recordTime}</span>
          <span className={styles.label}>Status:</span>
          <span className={styles.value}>
            {nomination.expired ? 'Expired' : 'Active'}
          </span>
        </div>
        <div className={styles.tbo}>
          <span className={styles.label}>TBO Link Status:</span>
          <span className={classnames([styles.value, styles[nomination.tboLinkStatus]])}>
            {showTboLinkStatus(nomination.tboLinkStatus)}
          </span>
          <span className={styles.label}>TBO Number:</span>
          <span className={styles.value}>{nomination.tboNumber}</span>
        </div>
      </div>
    </div>
  )
}

const NominationListPage = () => {
  const { fetching, newNomination, nominations, ownershipFilter, setOwnershipFilter, refreshNominations } =
    useNominationsViewModel()

  let nominationSection
  if (fetching) {
    nominationSection = (
      <div className={styles.centeredSpinner}>
        <LoadingSpinner isFullScreen={false} />
      </div>
    )
  } else if (nominations.length === 0) {
    nominationSection = <div className={styles.emptyList}>No nominations found</div>
  } else {
    nominationSection = nominations.map(NominationTile)
  }

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen}>
          <BargePoolUpdateBlock />
        </PrimaryMenu>
      )}>
      <div className={styles.container}>
        <div className={styles.section}>
          <header className={styles.header}>
            <h3>Nominations</h3>
            <div className={styles.menu}>
              <div className={styles.filter}>
                <KVSelector
                  label="Owner"
                  selected={ownershipFilter}
                  options={OwnershipOptions}
                  onChange={setOwnershipFilter}
                  tooltipText="Grouping table columns"
                />
              </div>
              <button onClick={refreshNominations} className={styles.refresh}>
                <Autorenew />
              </button>
              <Button onClick={newNomination} className={styles.button}>
                New Nomination
              </Button>
            </div>
          </header>
          <section className={styles.nominations}>{nominationSection}</section>
        </div>
      </div>
    </Layout>
  )
}

export default NominationListPage
