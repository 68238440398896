import {
  type Fleet,
  type GoalId,
  type GraphqlBargeType,
  type HubLikeId,
  HullType,
  type LaneId,
  LoadStatus,
  OverviewNominationVersionType,
  PickupType,
  RiskLevel,
  RiverLocationLite,
  TboLinkStatus,
  TboSubmissionStatusId,
  TripStatusId,
} from '../generated/graphql'

import type { FacilityLocation } from '../Domain/Facility'
import type { NominatedBargeWithReview } from '../Domain/Nomination'


export type NominationData = {
  id: string
  slug: string
  recordTime: Date
  tboLinkStatus: TboLinkStatus
  tboNumber: number | null
  owner: string | null
}

export type LatestNominationSummary = {
  id: string
  lane: LaneId | null
  origin: FacilityLocation | null
  destination: FacilityLocation | null
  expectedDepartureTime: Date | null
  owner: string | null
  towShape: {
    empty: number
    loaded: number
    strings: number | null
  } | null
  tboNumber: number | null
  tboLinkStatus: TboLinkStatus
  recordTime: Date
  expired: boolean
}

export type NominatedBargeTowBuildOrder = {
  latestInfo: string | null
}

export type NominatedBarge = {
  id: string
  name: string | null
  cargo: string | null
  hullType: HullType | null
  isPreselected: boolean
  isHot: boolean
  riskLevel: RiskLevel
  pickupType: PickupType
  tripStatus: TripStatusId
  destination: RiverLocationLite | null
  currentLocation: RiverLocationLite | null
  loadStatus: LoadStatus
  expectedLoadStatus: LoadStatus
  pickupFacility: FacilityLocation
  dropOffFacility: FacilityLocation
  towBuildOrder: NominatedBargeTowBuildOrder | null
  type: GraphqlBargeType
  fleet: Fleet | null
  distanceToPickup: number | null
}

export type StopsWithMetrics = {
  dwellMinutes: number | null
  distanceToNextStop: number | null
  travelMinutesToNextStop: number | null
  stop: RiverLocationLite
  bargesToPickup: NominatedBarge[]
  bargesToDrop: NominatedBarge[]
}

export type Boat = {
  boatId: string
  name: string | null
  abbreviation: string | null
}

export type TbnBarge = {
  pickupFacility: FacilityLocation
  dropOffFacility: FacilityLocation
  expectedLoadStatus: LoadStatus
}

export type Tow = {
  barges: NominatedBargeWithReview[]
  tbnBarges: TbnBarge[]
  hasTurnboat: boolean
  boat: Boat | null
  stopsWithMetrics: StopsWithMetrics[]
  efficiencyMetric: number | null
}

export type VersionedNominationTowConfiguration = {
  boat: string
  goal: GoalId
  hasTurnboat: boolean
  numberOfBarges: number
  numberOfEmptyBarges: number
  numberOfStrings: number | null
  includeBargeIds: string[]
  excludeBargeIds: string[]
  prioritizeHotBarges: boolean
}

export type VersionedNominationBargeFilers = {
  excludeBargeTypes: GraphqlBargeType[]
  excludeTboInfoBarges: boolean
  excludeTripStatuses: TripStatusId[]
  excludeShuttleMoves: boolean
  lane: LaneId
  maximumDraft: number | null
  expectedDepartureTime: Date | null
  towOrigin: HubLikeId
  towDestination: HubLikeId
  includeTBOs: number[]
}

export type VersionedNominationRequest = {
  towConfiguration: VersionedNominationTowConfiguration
  bargeFilters: VersionedNominationBargeFilers
}

export type OrderRevisionId = {
  number: number
  headerId: number
  revisionNumber: number
}

export type VersionedNominationReview = {
  receivedTBO: Array<{
    bargeId: string
    tbo: string
  }>
  leftTheBargePool: boolean[]
  changedRiskLevel: Array<{
    bargeId: string
    newRiskLevel: RiskLevel
  }>
}

export type NativeNominationVersionData = {
  id: string
  slug: string
  recordTime: Date
  type: OverviewNominationVersionType.Native
  nominationRequest: VersionedNominationRequest
  tow: Tow
  tboSubmissionStatus: TboSubmissionStatusId
  tboSubmissionMessage: string | null
  orderRevisionId: OrderRevisionId | null
  owner: string | null
  expired: boolean
}

export type ExternalNominationVersionData = {
  id: string
  slug: string
  recordTime: Date
  type: OverviewNominationVersionType.External
  nominationRequest: VersionedNominationRequest | null
  tow: Tow
  tboSubmissionStatus: TboSubmissionStatusId.Submitted
  orderRevisionId: OrderRevisionId
  owner: string | null
  expired: boolean
}

export type NominationVersionData = NativeNominationVersionData | ExternalNominationVersionData

export const isNativeVersion = (version: NominationVersionData): version is NativeNominationVersionData =>
  version.type === OverviewNominationVersionType.Native
export const isExternalVersion = (version: NominationVersionData): version is ExternalNominationVersionData =>
  version.type === OverviewNominationVersionType.External
