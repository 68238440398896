import { useCallback, useEffect, useMemo, useState } from 'react'

import { parseISO } from 'date-fns'
import * as R from 'ramda'
import { useLocation } from 'wouter'

import { getDataByColumns } from '../../../components/Table/NominatedBargesTable'
import { ELLIPSIS } from '../../../constants/constants'
import { getCompleteBargeGroupsIds } from '../../../Domain/Barge'
import { equals, showRiverLocation } from '../../../Domain/River'
import {
  BargeInPool,
  type GoalId,
  HubLike,
  HubLikeId,
  HullType,
  type LaneId,
  LoadStatus,
  OverviewNominationVersionType,
  type RiverLocationLite,
  TboLinkStatus,
  TboSubmissionStatus,
  TboSubmissionStatusId,
  useLaneBargesQuery,
} from '../../../generated/graphql'
import {
  formatBoat,
  formatBoolean,
  formatHours,
  formatHubLike,
  formatOptional,
  formatPercents,
} from '../../../lib/formatters'
import {
  type ExternalNominationVersionData,
  isExternalVersion,
  isNativeVersion,
  type NativeNominationVersionData,
  type NominatedBarge,
  type NominationData,
  type NominationVersionData,
  type StopsWithMetrics,
  type TbnBarge,
} from '../../../models/models'
import useNominationActionsModel from '../../../models/useNominationActionsModel'
import useNominationModel from '../../../models/useNominationModel'
import { useNominationRequestBridge } from '../../../providers/NominationRequestBridge'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { errorToast, successToast } from '../../../ui/Toast/Toast'
import { toString } from '../../../utils/date'
import { handleCSVDownload } from '../../../utils/downloadFile'
import { useAuthorizationContext } from '../../Account/AuthorizationContext'

import { collectVersionedFeedbackData } from './collectVersionedFeedbackData'
import { TBOSubmissionStatusLabels } from './NominationVersionSummary'

import type { NominatableBoat } from '../../../Domain/Nomination'

export type VersionLink = {
  readonly id: string
  readonly name: string
  readonly recordTime: string
  readonly path: string
  readonly type: OverviewNominationVersionType
  readonly active: boolean
  readonly expired: boolean
}

export type Navigation = {
  readonly links: VersionLink[]
  newNominationVersion: () => void
  redirectToLatest: () => void
}

export type NominationSummary = {
  readonly title: string
  readonly recordTime: string
  readonly tboLinkStatus: TboLinkStatus
  readonly orderNumber: number | null
  readonly canBeArchived: boolean
  readonly owner: string
}

export type NominatedBargeStatistics = {
  readonly quantity: number
  readonly empty: number
  readonly emptyRakes: number
  readonly emptyBoxes: number
  readonly loaded: number
  readonly loadedRakes: number
  readonly loadedBoxes: number
  readonly rakes: number
  readonly boxes: number
}

type TbnStatisticsEntry = {
  [LoadStatus.Loaded]: number
  [LoadStatus.Empty]: number
  quantity: number
  title: string
}

type TbnBargeStatistics = {
  readonly quantity: number
  readonly entries: TbnStatisticsEntry[]
}

type HotBargeStatistics = {
  readonly quantity: number
  readonly [HullType.Rake]: number
  readonly [HullType.Box]: number
}

type TboInfo = {
  readonly fullOrderNumber: string
  readonly headerId: number
  readonly revisionNumber: number
  readonly orderNumber: number
}

export type VersionSummary = {
  readonly recordTime: string
  readonly lane: string
  readonly origin: string
  readonly destination: string
  readonly expectedDepartureDate: string
  readonly operationalGoal: string
  readonly transitTime: string
  readonly dwellTime: string
  readonly vessel: string
  readonly hasTurnboat: string
  readonly totalStops: number
  readonly totalDestinations: number
  readonly totalBargeMiles: number
  readonly towScore: string
  readonly nominatedBargeStatistics: NominatedBargeStatistics
  readonly tbnBargeStatistics: TbnBargeStatistics
  readonly hotBargeStatistics: HotBargeStatistics
  readonly type: OverviewNominationVersionType
  readonly tboSubmissionStatus: TboSubmissionStatus
  readonly tboSubmissionMessage: string
  readonly tboInfo: TboInfo | null
  readonly owner: string
  readonly expired: boolean
}

export type Stop = {
  readonly code: string
  readonly mileagePoint: number
  readonly dropOffs: string[]
  readonly pickUps: string[]
  readonly inTowBarges: string[]
  readonly travelMinutesToNextStop: number | null
  readonly dwellMinutes: number | null
  readonly distanceToNextStop: number
  readonly bargeMiles: number
}

export type JourneyData = {
  readonly stops: Stop[]
  readonly boat: string
  readonly maximumBargesInTow: number
  readonly selectedStop: RiverLocationLite | null
  stopSelectionHandler: (location: RiverLocationLite | null) => void
}

export type SelectedExternalVersion = {
  readonly summary: VersionSummary
  readonly journey: JourneyData
  readonly nominatedBarges: NominatedBarge[]
  readonly canBeArchived: boolean
  readonly canBeSubmitted: boolean
  bargeNamesCallback: () => string
  downloadNominationCsvCallback: () => void
}

export type SelectedNativeVersion = {
  readonly summary: VersionSummary
  readonly journey: JourneyData
  readonly nominatedBarges: NominatedBarge[]
  readonly canBeArchived: boolean
  readonly canBeSubmitted: boolean
  bargeNamesCallback: () => string
  downloadNominationCsvCallback: () => void
}

export type SelectedVersion = SelectedNativeVersion | SelectedExternalVersion

export type FetchingNominationDetailsViewModel = {
  readonly fetching: true
}

export type FetchedNominationDetailsViewModel = {
  readonly fetching: false
  readonly navigation: Navigation
  readonly nominationSummary: NominationSummary
  readonly selectedVersion: SelectedVersion | null
  refreshNomination: () => void
  readonly isNominationArchiving: boolean
  initNominationArchiving: () => void
  cancelNominationArchiving: () => void
  archiveNominationHandler: () => Promise<any>
  readonly isVersionArchiving: boolean
  initVersionArchiving: () => void
  cancelVersionArchiving: () => void
  archiveNominationVersionHandler: () => Promise<any>
  readonly isVersionSubmitting: boolean
  initVersionSubmission: () => void
  cancelVersionSubmission: () => void
  submitNominationVersionHandler: () => Promise<any>
  handleExportFeedback: () => void
}

type NominationDetailsViewModel = FetchingNominationDetailsViewModel | FetchedNominationDetailsViewModel

export const isFetching = (ndvm: NominationDetailsViewModel): ndvm is FetchingNominationDetailsViewModel =>
  ndvm.fetching
export const isFetched = (ndvm: NominationDetailsViewModel): ndvm is FetchedNominationDetailsViewModel => !ndvm.fetching

const UNKNOWN_BOAT = 'Unknown'
const IMPORTED_FROM_TBO = 'Imported from TBO'

const buildNominatedBargeStatistics = (barges: NominatedBarge[]): NominatedBargeStatistics => {
  const initialBargeStatistics: NominatedBargeStatistics = {
    quantity: 0,
    empty: 0,
    emptyRakes: 0,
    emptyBoxes: 0,
    loaded: 0,
    loadedRakes: 0,
    loadedBoxes: 0,
    rakes: 0,
    boxes: 0,
  }

  const asKey = (loadStatus: LoadStatus, hullType: HullType): string => `${loadStatus}:${hullType}`

  const lensSets: Record<string, R.Lens<NominatedBargeStatistics, number>[]> = {
    [asKey(LoadStatus.Empty, HullType.Rake)]: [R.lensProp('empty'), R.lensProp('rakes'), R.lensProp('emptyRakes')],
    [asKey(LoadStatus.Empty, HullType.Box)]: [R.lensProp('empty'), R.lensProp('boxes'), R.lensProp('emptyBoxes')],
    [asKey(LoadStatus.Loaded, HullType.Rake)]: [R.lensProp('loaded'), R.lensProp('rakes'), R.lensProp('loadedRakes')],
    [asKey(LoadStatus.Loaded, HullType.Box)]: [R.lensProp('loaded'), R.lensProp('boxes'), R.lensProp('loadedBoxes')],
  }
  const quantityLens: R.Lens<NominatedBargeStatistics, number> = R.lensProp('quantity')

  const reducer = (acc: NominatedBargeStatistics, barge: NominatedBarge) => {
    const lensesForCurrentBarge: R.Lens<NominatedBargeStatistics, number>[] =
      lensSets[asKey(barge.loadStatus, barge.hullType ?? HullType.Box)]

    if (!lensesForCurrentBarge) return acc

    const lenses: R.Lens<NominatedBargeStatistics, number>[] = R.append(
      quantityLens,
      lensSets[asKey(barge.loadStatus, barge.hullType ?? HullType.Box)]
    )
    const modifiers: ((value: NominatedBargeStatistics) => NominatedBargeStatistics)[] = R.map(
      lens => R.over(lens, R.inc),
      lenses
    )

    // @ts-ignore
    return R.pipe(...modifiers)(acc)
  }

  return R.reduce(reducer, initialBargeStatistics, barges)
}

type TbnStatisticsAggregator = Record<string, TbnStatisticsEntry>

const buildTbnStatistics = (tbnBarges: TbnBarge[]): TbnBargeStatistics => {
  const groupTitle = (pickup: string, dropOff: string) => `${pickup} → ${dropOff}`
  const reducer = (acc: TbnStatisticsAggregator, barge: TbnBarge): TbnStatisticsAggregator => {
    const pickupLabel = showRiverLocation(barge.pickupFacility)
    const dropOffLabel = showRiverLocation(barge.dropOffFacility)
    const key = `${pickupLabel}-${dropOffLabel}`

    const currentEntry = acc[key] ?? {
      [LoadStatus.Loaded]: 0,
      [LoadStatus.Empty]: 0,
      quantity: 0,
      title: groupTitle(pickupLabel, dropOffLabel),
    }
    const modifiedEntry = R.pipe(
      R.assoc(barge.expectedLoadStatus, R.inc(currentEntry[barge.expectedLoadStatus])),
      R.assoc('quantity', R.inc(currentEntry.quantity))
    )(currentEntry)

    return R.assoc(key, modifiedEntry, acc)
  }

  const entries: TbnStatisticsEntry[] = R.pipe(R.reduce(reducer, {}), R.values)(tbnBarges)
  const quantity = R.pipe(
    R.map((e: TbnStatisticsEntry) => e.quantity),
    R.sum
  )(entries)

  return {
    quantity,
    entries,
  }
}

const buildTBOSubmissionStatus = (tboSubmissionStatusId: TboSubmissionStatusId) => {
  const displayName = TBOSubmissionStatusLabels[tboSubmissionStatusId]
  return { id: tboSubmissionStatusId, displayName }
}

const buildHotStatistics = (barges: NominatedBarge[]): HotBargeStatistics => {
  const hotBarges = R.filter(b => b.isHot, barges)
  const quantity = hotBarges.length
  const rakes = R.filter(b => b.hullType === HullType.Rake, hotBarges).length
  const boxes = R.filter(b => b.hullType === HullType.Box, hotBarges).length

  return {
    quantity,
    [HullType.Rake]: rakes,
    [HullType.Box]: boxes,
  }
}

const computeTransitTime = (stopsWithMetrics: StopsWithMetrics[]): number => {
  return (
    R.pipe(
      R.map((s: StopsWithMetrics) => s.travelMinutesToNextStop ?? 0),
      R.sum
    )(stopsWithMetrics) / 60
  )
}

const computeDwellTime = (stopsWithMetrics: StopsWithMetrics[]): number => {
  return (
    R.pipe(
      R.map((s: StopsWithMetrics) => s.dwellMinutes ?? 0),
      R.sum
    )(stopsWithMetrics) / 60
  )
}

const computeTotalDestinations = (barges: NominatedBarge[]): number => {
  const destinations = R.map(b => (b.destination ? showRiverLocation(b.destination) : null), barges)
  return new Set(destinations).size
}

type JourneyDataAccumulator = {
  previous: Stop | null
  stops: Stop[]
}
const buildJourney = (
  stopsWithMetrics: StopsWithMetrics[],
  boat: string,
  selectedStop: RiverLocationLite | null,
  setSelectedStop: (stop: RiverLocationLite | null) => void
): JourneyData => {
  const initial: JourneyDataAccumulator = { previous: null, stops: [] }
  const stopsAccumulator: JourneyDataAccumulator = R.reduce(
    (acc: JourneyDataAccumulator, s: StopsWithMetrics): JourneyDataAccumulator => {
      const dropOffs = R.map(b => b.id, s.bargesToDrop)
      const pickUps = R.map(b => b.id, s.bargesToPickup)

      const alreadyInTow = acc.previous?.inTowBarges ?? []
      const inTowBarges = R.difference(R.union(alreadyInTow, pickUps), dropOffs)

      const distanceToNextStop = s.distanceToNextStop ?? 0
      const bargeMiles = inTowBarges.length * distanceToNextStop

      const currentStop = {
        code: s.stop.code,
        mileagePoint: s.stop.mileagePoint,
        dropOffs,
        pickUps,
        inTowBarges,
        travelMinutesToNextStop: s.travelMinutesToNextStop,
        dwellMinutes: s.dwellMinutes,
        distanceToNextStop,
        bargeMiles,
      }

      return {
        previous: currentStop,
        stops: [...acc.stops, currentStop],
      }
    },
    initial,
    stopsWithMetrics
  )
  const { stops } = stopsAccumulator

  const maximumBargesInTow = R.reduce((max, stop) => R.max(max, stop.inTowBarges.length), 0, stops)

  return {
    boat,
    stops,
    maximumBargesInTow,
    selectedStop,
    stopSelectionHandler: setSelectedStop,
  }
}

export const getNominatedBargesForStop = (
  journey: JourneyData,
  nominatedBarges: NominatedBarge[],
  selectedStop: RiverLocationLite | null
): NominatedBarge[] => {
  if (!selectedStop) return nominatedBarges

  const selectedJourneyStop = journey.stops.find(stop => equals(stop, selectedStop))
  if (!selectedJourneyStop) return []

  return selectedJourneyStop.inTowBarges.flatMap(bargeId => nominatedBarges.filter(barge => barge.id === bargeId))
}

const buildNativeVersionSummary = (
  selectedVersion: NativeNominationVersionData,
  totalBargeMiles: number,
  lanes: Record<LaneId, string>,
  goals: Record<GoalId, { label: string; description: string }>,
  hubs: Record<HubLikeId, HubLike>,
  boats: NominatableBoat[]
): VersionSummary => {
  const { recordTime, nominationRequest, tow, owner } = selectedVersion
  const { towConfiguration, bargeFilters } = nominationRequest

  const formattedBoatIdentity = formatBoat(towConfiguration.boat, boats)

  const tboInfo = selectedVersion.orderRevisionId
    ? {
        fullOrderNumber: `${selectedVersion.orderRevisionId.number} rev${selectedVersion.orderRevisionId.revisionNumber}`,
        headerId: selectedVersion.orderRevisionId.headerId,
        revisionNumber: selectedVersion.orderRevisionId.revisionNumber,
        orderNumber: selectedVersion.orderRevisionId.number,
    }
    : null

  const tboSubmissionMessage =
    selectedVersion.tboSubmissionStatus === TboSubmissionStatusId.Pending && selectedVersion.tboSubmissionMessage
      ? toString(parseISO(selectedVersion.tboSubmissionMessage))
      : selectedVersion.tboSubmissionMessage ?? ''

  return {
    type: selectedVersion.type,
    recordTime: toString(recordTime),
    lane: bargeFilters ? lanes[bargeFilters.lane] : ELLIPSIS,
    origin: bargeFilters ? formatHubLike(bargeFilters.towOrigin, hubs) : ELLIPSIS,
    destination: bargeFilters ? formatHubLike(bargeFilters.towDestination, hubs) : ELLIPSIS,
    expectedDepartureDate: bargeFilters ? formatOptional(bargeFilters.expectedDepartureTime, toString) : ELLIPSIS,
    operationalGoal: towConfiguration ? goals[towConfiguration.goal].label : ELLIPSIS,
    transitTime: formatHours(computeTransitTime(tow.stopsWithMetrics)),
    dwellTime: formatHours(computeDwellTime(tow.stopsWithMetrics)),
    vessel: formattedBoatIdentity,
    hasTurnboat: towConfiguration ? formatBoolean(towConfiguration.hasTurnboat) : ELLIPSIS,
    totalStops: tow.stopsWithMetrics.length,
    totalDestinations: computeTotalDestinations(tow.barges),
    totalBargeMiles,
    towScore: formatOptional(tow.efficiencyMetric, formatPercents),
    nominatedBargeStatistics: buildNominatedBargeStatistics(tow.barges),
    hotBargeStatistics: buildHotStatistics(tow.barges),
    tbnBargeStatistics: buildTbnStatistics(tow.tbnBarges),
    tboSubmissionStatus: buildTBOSubmissionStatus(R.defaultTo(ELLIPSIS, selectedVersion.tboSubmissionStatus)),
    tboSubmissionMessage,
    tboInfo,
    owner: owner ?? IMPORTED_FROM_TBO,
    expired: selectedVersion.expired,
  }
}

const buildExternalVersionSummary = (
  selectedVersion: ExternalNominationVersionData,
  totalBargeMiles: number,
  lanes: Record<LaneId, string>,
  goals: Record<GoalId, { label: string; description: string }>,
  hubs: Record<HubLikeId, HubLike>,
  boats: NominatableBoat[]
): VersionSummary => {
  const { recordTime, tow, nominationRequest, owner } = selectedVersion

  let nominationRequestSummary = null
  if (nominationRequest) {
    const { towConfiguration, bargeFilters } = nominationRequest

    nominationRequestSummary = {
      lane: lanes[bargeFilters.lane],
      origin: formatHubLike(bargeFilters.towOrigin, hubs),
      destination: formatHubLike(bargeFilters.towDestination, hubs),
      expectedDepartureDate: formatOptional(bargeFilters.expectedDepartureTime, toString),
      operationalGoal: goals[towConfiguration.goal].label,
      vessel: formatBoat(towConfiguration.boat, boats),
      hasTurnboat: formatBoolean(towConfiguration.hasTurnboat),
    }
  } else {
    nominationRequestSummary = {
      lane: ELLIPSIS,
      origin: ELLIPSIS,
      destination: ELLIPSIS,
      expectedDepartureDate: ELLIPSIS,
      operationalGoal: ELLIPSIS,
      vessel: ELLIPSIS,
      hasTurnboat: ELLIPSIS,
    }
  }

  const tboInfo = selectedVersion.orderRevisionId
    ? {
        fullOrderNumber: `${selectedVersion.orderRevisionId.number} rev${selectedVersion.orderRevisionId.revisionNumber}`,
        headerId: selectedVersion.orderRevisionId.headerId,
        revisionNumber: selectedVersion.orderRevisionId.revisionNumber,
        orderNumber: selectedVersion.orderRevisionId.number,
    }
    : null

  return {
    type: selectedVersion.type,
    recordTime: toString(recordTime),
    ...nominationRequestSummary,
    transitTime: formatHours(computeTransitTime(tow.stopsWithMetrics)),
    dwellTime: formatHours(computeDwellTime(tow.stopsWithMetrics)),
    totalStops: tow.stopsWithMetrics.length,
    totalDestinations: computeTotalDestinations(tow.barges),
    totalBargeMiles,
    towScore: formatOptional(tow.efficiencyMetric, formatPercents),
    nominatedBargeStatistics: buildNominatedBargeStatistics(tow.barges),
    tbnBargeStatistics: buildTbnStatistics(tow.tbnBarges),
    hotBargeStatistics: buildHotStatistics(tow.barges),
    tboSubmissionStatus: buildTBOSubmissionStatus(R.defaultTo(ELLIPSIS, selectedVersion.tboSubmissionStatus)),
    tboSubmissionMessage: '',
    tboInfo,
    owner: owner ?? 'Imported from TBO',
    expired: selectedVersion.expired,
  }
}

const totalBargeMilesForJourney = (journey: JourneyData): number => {
  return R.pipe(
    R.map((s: Stop) => s.bargeMiles),
    R.sum
  )(journey.stops)
}

const buildSelectedVersion = (
  selectedVersion: NominationVersionData,
  numberOfVersions: number,
  lanes: Record<LaneId, string>,
  goals: Record<GoalId, { label: string; description: string }>,
  hubs: Record<HubLikeId, HubLike>,
  boats: NominatableBoat[],
  selectedStop: RiverLocationLite | null,
  setSelectedStop: (stop: RiverLocationLite | null) => void
): SelectedVersion => {
  const bargeNamesCallback = () => R.map(b => b.name, selectedVersion.tow.barges).join(', ')

  const downloadNominationCsvCallback = () => {
    const nominatedBarges = selectedVersion.tow.barges
    const data = getDataByColumns(nominatedBarges)
    handleCSVDownload({ data })
  }

  if (isNativeVersion(selectedVersion)) {
    const { boat } = selectedVersion.nominationRequest.towConfiguration
    const formattedBoatIdentity = formatBoat(boat, boats)
    const journey = buildJourney(
      selectedVersion.tow.stopsWithMetrics,
      formattedBoatIdentity,
      selectedStop,
      setSelectedStop
    )

    const totalBargeMiles = totalBargeMilesForJourney(journey)
    const canBeArchived =
      (selectedVersion.tboSubmissionStatus === TboSubmissionStatusId.NotSubmitted ||
        selectedVersion.tboSubmissionStatus === TboSubmissionStatusId.Failed) &&
      numberOfVersions > 1

    const canBeSubmitted =
      selectedVersion.tboSubmissionStatus === TboSubmissionStatusId.NotSubmitted ||
      selectedVersion.tboSubmissionStatus === TboSubmissionStatusId.Failed

    return {
      summary: buildNativeVersionSummary(selectedVersion, totalBargeMiles, lanes, goals, hubs, boats),
      journey,
      nominatedBarges: selectedVersion.tow.barges,
      canBeArchived,
      canBeSubmitted,
      bargeNamesCallback,
      downloadNominationCsvCallback,
    }
  }
  if (isExternalVersion(selectedVersion)) {
    const boatForJourney = selectedVersion.nominationRequest?.towConfiguration.boat ?? UNKNOWN_BOAT
    const journey = buildJourney(selectedVersion.tow.stopsWithMetrics, boatForJourney, selectedStop, setSelectedStop)

    const totalBargeMiles = totalBargeMilesForJourney(journey)

    return {
      summary: buildExternalVersionSummary(selectedVersion, totalBargeMiles, lanes, goals, hubs, boats),
      journey,
      nominatedBarges: selectedVersion.tow.barges,
      canBeArchived: false,
      canBeSubmitted: false,
      bargeNamesCallback,
      downloadNominationCsvCallback,
    }
  }
  throw new TypeError('Version is not native nor internal')
}

const buildNavigation = (
  nominationId: string,
  versions: NominationVersionData[],
  latestVersionId: string,
  navigate: (to: string, options?: { replace?: boolean }) => void,
  currentVersionId: string | null,
  setCurrentVersionId: (versionId: string) => void
): Navigation => {
  const versionLinks = R.pipe(
    R.sortBy((v: NominationVersionData) => v.recordTime),
    R.reverse,
    R.map((v: NominationVersionData) => {
      return {
        id: v.id,
        name: v.slug,
        recordTime: toString(v.recordTime),
        path: `/nomination/${nominationId}/version/${v.id}`,
        type: v.type,
        active: v.id === currentVersionId,
        expired: v.expired,
      }
    })
  )(versions)

  const redirectToLatest = () => {
    setCurrentVersionId(latestVersionId)
    navigate(`/nomination/${nominationId}/version/${latestVersionId}`, { replace: true })
  }

  const newNominationVersion = () => {
    navigate(`/nomination/${nominationId}/version/new`, { replace: true })
  }

  return {
    links: versionLinks,
    newNominationVersion,
    redirectToLatest,
  }
}

const buildNominationSummary = (nomination: NominationData): NominationSummary => {
  const { id, recordTime } = nomination

  return {
    title: id,
    recordTime: toString(recordTime),
    tboLinkStatus: nomination.tboLinkStatus,
    orderNumber: nomination.tboNumber,
    canBeArchived: nomination.tboLinkStatus !== TboLinkStatus.Established,
    owner: nomination.owner ?? IMPORTED_FROM_TBO,
  }
}

const useNominationDetailsViewModel = (nominationId: string, versionId: string | null): NominationDetailsViewModel => {
  const { fetching, nomination, versions, refreshNomination } = useNominationModel(nominationId)
  const {
    nominationId: requestNominationId,
    nominationVersionId: requestNominationVersionId,
    setNativeNominationRequest,
    setExternalNominationRequest,
  } = useNominationRequestBridge()

  const { archiveNomination, archiveNominationVersion, submitNominationVersion } = useNominationActionsModel()

  const [, navigate] = useLocation()
  const { lanes, goals, hubs, boats } = useSettingsContext()
  const [refetchOnEntry, setRefetch] = useState<boolean>(false)

  const [isNominationArchiving, setNominationArchiving] = useState<boolean>(false)
  const [isVersionArchiving, setVersionArchiving] = useState<boolean>(false)

  const [isVersionSubmitting, setVersionSubmitting] = useState<boolean>(false)

  const sortedVersions = useMemo(() => R.reverse(R.sortBy(R.prop('recordTime'), versions)), [versions])
  const latestVersionId = useMemo(() => sortedVersions[0]?.id, [sortedVersions])
  const [currentVersionId, setCurrentVersionId] = useState(versionId || latestVersionId || null)
  const [selectedStop, setSelectedStop] = useState<RiverLocationLite | null>(null)

  const selectedVersionData = useMemo(
    () => R.find(v => v.id === currentVersionId, versions),
    [currentVersionId, versions]
  )
  const { getUserInfo } = useAuthorizationContext()
  const userInfo = getUserInfo()

  useEffect(() => {
    if (!refetchOnEntry) {
      refreshNomination({ requestPolicy: 'network-only' })
      setRefetch(true)
    }
  }, [refetchOnEntry, setRefetch, refreshNomination])

  useEffect(() => {
    if (currentVersionId !== versionId) {
      setCurrentVersionId(versionId)
    }
  }, [currentVersionId, versionId])

  useEffect(() => {
    if (!currentVersionId) {
      setCurrentVersionId(latestVersionId)
    }
  }, [latestVersionId, currentVersionId])

  useEffect(() => {
    const shouldBeUpdated =
      selectedVersionData &&
      currentVersionId &&
      (nominationId !== requestNominationId || currentVersionId !== requestNominationVersionId)
    if (shouldBeUpdated && selectedVersionData.type === OverviewNominationVersionType.Native) {
      setNativeNominationRequest(nominationId, currentVersionId, selectedVersionData.nominationRequest)
    } else if (shouldBeUpdated && selectedVersionData.type === OverviewNominationVersionType.External) {
      setExternalNominationRequest(nominationId, currentVersionId, selectedVersionData.nominationRequest)
    }
  }, [
    selectedVersionData,
    nominationId,
    currentVersionId,
    requestNominationId,
    requestNominationVersionId,
    setNativeNominationRequest,
    setExternalNominationRequest,
  ])

  useEffect(() => {
    if (selectedVersionData && selectedVersionData.tboSubmissionStatus === TboSubmissionStatusId.Pending) {
      const timeout = setTimeout(() => refreshNomination({ requestPolicy: 'network-only' }), 10000)
      return () => clearTimeout(timeout)
    }

    return undefined
  }, [selectedVersionData, refreshNomination])

  const initNominationArchiving = useCallback(() => {
    setNominationArchiving(true)
  }, [setNominationArchiving])

  const cancelNominationArchiving = useCallback(() => {
    setNominationArchiving(false)
  }, [setNominationArchiving])

  const archiveNominationHandler = useCallback(async () => {
    const result = await archiveNomination(nominationId)
    setNominationArchiving(false)
    if (result) {
      successToast(`Nomination ${nominationId} has been archived successfully.`)
      navigate('/nominations')
    } else {
      errorToast('Failed to archive nomination')
    }
  }, [archiveNomination, nominationId, navigate])

  const initVersionArchiving = useCallback(() => {
    setVersionArchiving(true)
  }, [setVersionArchiving])

  const cancelVersionArchiving = useCallback(() => {
    setVersionArchiving(false)
  }, [setVersionArchiving])

  const archiveNominationVersionHandler = useCallback(async () => {
    if (!currentVersionId) {
      errorToast('Cannot archive version without version id')
      return
    }

    const result = await archiveNominationVersion(nominationId, currentVersionId)
    setNominationArchiving(false)
    if (result) {
      successToast(`Nomination version ${currentVersionId} has been archived successfully.`)
      refreshNomination({ requestPolicy: 'network-only' })
      setCurrentVersionId(null)
      if (currentVersionId === latestVersionId) {
        const versionToRedirect = sortedVersions[1]?.id
        const redirectTo = versionToRedirect
          ? `/nomination/${nominationId}/version/${versionToRedirect}`
          : `/nomination/${nominationId}`
        navigate(redirectTo, { replace: true })
      } else {
        navigate(`/nomination/${nominationId}/version/${latestVersionId}`, { replace: true })
      }
    } else {
      errorToast('Failed to archive nomination')
    }
  }, [
    archiveNominationVersion,
    nominationId,
    currentVersionId,
    navigate,
    refreshNomination,
    latestVersionId,
    sortedVersions,
    setCurrentVersionId,
  ])

  const initVersionSubmission = useCallback(() => {
    setVersionSubmitting(true)
  }, [setVersionSubmitting])

  const cancelVersionSubmission = useCallback(() => {
    setVersionSubmitting(false)
  }, [setVersionSubmitting])

  const submitNominationVersionHandler = useCallback(async () => {
    if (!currentVersionId) {
      errorToast('Cannot submit version without version id')
      return
    }

    const result = await submitNominationVersion(nominationId, currentVersionId)
    setVersionSubmitting(false)
    if (result) {
      successToast(`Nomination version ${currentVersionId} has been queued for TBO submission successfully.`)
      refreshNomination({ requestPolicy: 'network-only' })
    } else {
      errorToast('Failed to archive nomination')
    }
  }, [submitNominationVersion, nominationId, currentVersionId, setVersionSubmitting, refreshNomination])

  const selectedVersion = selectedVersionData
    ? buildSelectedVersion(
        selectedVersionData,
        versions.length,
        lanes,
        goals,
        hubs,
        boats,
        selectedStop,
        setSelectedStop
      )
    : null

  const [{ data: bargePoolData }, reexecuteQuery] = useLaneBargesQuery({
    pause: true,
  })

  const bargePool: BargeInPool[] = useMemo(
    () => (bargePoolData?.lanes?.[0]?.barges || []) as BargeInPool[],
    [bargePoolData]
  )

  const BargeGroupIds = useMemo(() => getCompleteBargeGroupsIds(bargePool), [bargePool])

  useEffect(() => {
    if (fetching) return undefined

    const timerId = setTimeout(() => {
      reexecuteQuery({ requestPolicy: 'network-only' })
    }, 1000)

    return () => clearTimeout(timerId)
  }, [bargePool, fetching, reexecuteQuery])

  const handleExportFeedback = useCallback(() => {
    if (!selectedVersionData || !selectedVersion || !nomination || !userInfo) return

    reexecuteQuery({ requestPolicy: 'network-only' })

    const exportTime = new Date()
    collectVersionedFeedbackData(userInfo, nomination, versions, bargePool, exportTime, BargeGroupIds)
  }, [
    selectedVersionData,
    selectedVersion,
    nomination,
    versions,
    userInfo,
    bargePool,
    reexecuteQuery,
    BargeGroupIds,
  ])

  if (!nomination || fetching) {
    return { fetching: true }
  }

  const navigation = buildNavigation(
    nominationId,
    versions,
    latestVersionId,
    navigate,
    currentVersionId,
    setCurrentVersionId
  )
  const nominationSummary = buildNominationSummary(nomination)

  return {
    fetching,
    navigation,
    nominationSummary,
    selectedVersion,
    refreshNomination: () => refreshNomination({ requestPolicy: 'network-only' }),
    isNominationArchiving,
    initNominationArchiving,
    cancelNominationArchiving,
    archiveNominationHandler,
    isVersionArchiving,
    initVersionArchiving,
    cancelVersionArchiving,
    archiveNominationVersionHandler,
    isVersionSubmitting,
    initVersionSubmission,
    cancelVersionSubmission,
    submitNominationVersionHandler,
    handleExportFeedback,
  }
}

export default useNominationDetailsViewModel
