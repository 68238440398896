import { ELLIPSIS } from '../constants/constants'
import {
  Fleet,
  GoalId,
  GraphqlBargeType,
  HullType,
  LoadStatus,
  NominatedTbnBarge,
  PickupType,
  RiskLevel,
  RiverLocationLite,
  TripStatusId,
} from '../generated/graphql'
import { durationComponentsFromMinutes } from '../utils/date'

import { FacilityLocation } from './Facility'
import { equals } from './River'

export type TowNominationInput = {
  goal: GoalId
  numberOfLoaded: number
  numberOfEmpties: number
  numberOfStrings: number | null
  preselectedBarges: string[]
  boat?: string
  hasTurnboat: boolean
}

export type NominationInput = {
  prioritizeHotBarges: boolean
  towConfiguration: TowNominationInput
}

export type NominatableBoat = {
  name: string | null
  boatId: any
  abbreviation: string | null
}

export type NominatedBarge = {
  id: any
  name: string | null
  cargo: string | null
  hullType: HullType | null
  isPreselected: boolean
  isHot: boolean
  riskLevel: RiskLevel
  pickupType: PickupType
  tripStatus: TripStatusId
  destination: RiverLocationLite | null
  currentLocation: RiverLocationLite | null
  pickupFacility: FacilityLocation
  dropOffFacility: FacilityLocation
  towBuildOrder: { __typename?: 'TowBuildOrder'; latestInfo: string | null } | null
  loadStatus: LoadStatus
  expectedLoadStatus: LoadStatus
  type: GraphqlBargeType
  fleet: Fleet | null
  distanceToPickup: number | null
}

export type NominatedBargeWithReview = NominatedBarge & {
  review: { receivedTBO?: string; leftTheBargePool: boolean; riskLevel?: RiskLevel }
}

export const isNominatedBargeWithReview = (barge: any): barge is NominatedBargeWithReview => 'review' in barge

export type NominatedTow = {
  efficiencyMetric: number | null
  boat: NominatableBoat
  barges: NominatedBarge[]
  stopsWithMetrics: {
    dwellMinutes: number | null
    distanceToNextStop: number | null
    travelMinutesToNextStop: number | null
    stop: RiverLocationLite
  }[]
  hasTurnboat: boolean
  tbnBarges: NominatedTbnBarge[]
}

export type NominatedTowStop = RiverLocationLite & {
  dropOffs: string[]
  pickUps: string[]
  inTowBarges: string[]
  travelMinutesToNextStop: number | null
  dwellMinutes: number | null
  distanceToNextStop: number
}

export const collectTowChanges = (stop: RiverLocationLite, barges: NominatedBarge[]) =>
  barges.reduce(
    (acc: { dropOffs: string[]; pickUps: string[] }, barge) => {
      if (barge.dropOffFacility && equals(stop, barge.dropOffFacility)) {
        acc.dropOffs.push(barge.id)
      }

      if (barge.pickupFacility && equals(stop, barge.pickupFacility)) {
        acc.pickUps.push(barge.id)
      }

      return acc
    },
    { dropOffs: [], pickUps: [] }
  )

export const createNominationStops = ({
  barges,
  stopsWithMetrics,
}: Pick<NominatedTow, 'barges' | 'stopsWithMetrics'>) =>
  stopsWithMetrics.reduce(
    (acc: NominatedTowStop[], { stop, distanceToNextStop, travelMinutesToNextStop, dwellMinutes }, index) => {
      const { dropOffs, pickUps } = collectTowChanges(stop, barges)

      const inTowBarges = index
        ? [...acc[index - 1].inTowBarges.filter(id => !dropOffs.includes(id)), ...pickUps]
        : pickUps

      return [
        ...acc,
        {
          ...stop,
          travelMinutesToNextStop,
          distanceToNextStop: distanceToNextStop ?? 0,
          dwellMinutes,
          dropOffs,
          pickUps,
          inTowBarges,
        },
      ]
    },
    []
  )

export function showIncludedTBOs(includeTBOs: number[]) {
  return includeTBOs.length > 0 ? includeTBOs.join(', ') : ELLIPSIS
}

export function showTransitTime(time: number) {
  const { day, hour, minute } = durationComponentsFromMinutes(time)

  return day > 0 ? `${day}d ${hour}h ${minute}m` : `${hour}h ${minute}m`
}

export function showTruncatedMilesMetric(value: number) {
  return value.toFixed(1)
}

export function showBoat(boat: string | undefined) {
  return boat || 'not selected'
}
