import React, { useState, type FC } from 'react'

import { DownloadOutlined } from '@mui/icons-material'
import classnames from 'classnames'

import CopyDataButton from '../../../components/Buttons/CopyDataButton'
import GroupBySelector from '../../../components/Nomination/GroupBySelector'
import { NominatedBargesTable, Theme } from '../../../components/Table/NominatedBargesTable'
import {
  groupByKeys,
  GroupByType,
  sortByKeysN,
  SortByTypeN,
  sortOrderKeys,
  SortOrderType,
} from '../../../ui/Table/TableColumnConfig'

import styles from './styles.module.scss'
import TowActionButton from './TowActionButton'

import type { NominatedBarge } from '../../../models/models'

type BargeListBlockProps = {
  readonly nominatedBarges: NominatedBarge[]
  bargeNamesCallback: () => string
  downloadNominationCsvCallback: () => void
  withReviewsVisible: boolean
}

const theme: Theme = {
  columns: {
    [SortByTypeN.IsAvailable]: styles.cell,
    [SortByTypeN.TboInfo]: styles.tboCell,
    [SortByTypeN.Name]: styles.nameCell,
    [SortByTypeN.IsAtRisk]: styles.isAtRiskCell,
    [SortByTypeN.Cargo]: undefined,
    [SortByTypeN.Destination]: styles.cell,
    [SortByTypeN.CurrentLocation]: styles.cell,
    [SortByTypeN.HullType]: styles.hullTypeCell,
    [SortByTypeN.TripStatus]: styles.cell,
    [SortByTypeN.Pickup]: styles.locationCell,
    [SortByTypeN.DropOff]: styles.locationCell,
    [SortByTypeN.PickupType]: styles.cell,
    [SortByTypeN.Type]: styles.cell,
    [SortByTypeN.Fleet]: styles.cell,
    [SortByTypeN.None]: styles.cell,
  },
}

const BargeListBlock: FC<BargeListBlockProps> = ({
  nominatedBarges,
  bargeNamesCallback,
  downloadNominationCsvCallback,
  withReviewsVisible,
}) => {
  const [groupBy, setGroupBy] = useState<GroupByType>(GroupByType.None)
  const [sortBy, setSortBy] = useState<SortByTypeN>(SortByTypeN.None)
  const [sortOrder, setSortOrder] = useState<SortOrderType>(SortOrderType.Ascending)

  return (
    <section className={classnames(styles.container)}>
      <div className={classnames(styles.nominatedBarges)}>
        <header className={classnames(styles.bargeListHeader)}>
          <GroupBySelector
            groupBy={groupBy}
            groupByKeys={groupByKeys}
            handleGroupByChange={setGroupBy}
            sortBy={sortBy}
            sortByKeys={sortByKeysN}
            handleSortByChange={setSortBy}
            sortOrder={sortOrder}
            sortOrderKeys={sortOrderKeys}
            handleSortOrderChange={setSortOrder}
          />

          <TowActionButton
            label="Export Table to CSV"
            getData={downloadNominationCsvCallback}
            Icon={DownloadOutlined}
            className={styles.towActionButton}
          />
          <CopyDataButton
            getData={bargeNamesCallback}
            successText="Copied barge names ✔"
            tooltipText="Copy Barge Names"
          />
        </header>
        <div className={classnames(styles.bargeList)}>
          <NominatedBargesTable
            hasReviews={withReviewsVisible}
            barges={nominatedBarges}
            theme={theme}
            groupBy={groupBy}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
        </div>
      </div>
    </section>
  )
}

export default BargeListBlock
